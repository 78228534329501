import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { CaptureConsole } from '@sentry/integrations';

import HomePage from 'components/pages/HomePage';
import PricingPage from 'components/pages/PricingPage';
// import UserPage from 'containers/pages/UserPage';
// import EmbedRecordPage from 'containers/pages/EmbedRecordPage';
// import RecordsBrowser from "components/pages/RecordsBrowser";
// import Slideshow from "components/pages/Slideshow";
import config from 'config';
// import Spm from "components/spm/App";
import AboutPage from './pages/AboutPage';
import ScrollToTop from './ScrollToTop';
import SolutionsPage from './pages/SolutionsPage';
import { BeatLoader } from 'react-spinners';
const Spm = lazy(() => import('components/spm/App'));
const RecordsBrowser = lazy(() => import('components/pages/RecordsBrowser'));
const EmbedRecordPage = lazy(() => import('containers/pages/EmbedRecordPage'));
const Slideshow = lazy(() => import('components/pages/Slideshow'));
const RecorderTest = lazy(() => import('components/pages/RecorderTest'));

const loaderStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -50,
  marginLeft: -50,
  width: 100,
  height: 100,
};

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    // replaysOnErrorSampleRate: 1.0,
    integrations: [
      // new Sentry.Replay(),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    release: "slidespiel@" + config.version,
  });
}

try {
  localStorage.setItem('debug', config.debug);
}
catch {
  console.warn("local storage not available");
}

export default class Root extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  render() {
    const { hostname } = window.location;
    const spmDomain = config.spmDomains.find(x => x.domain === hostname);
    return (
      <Provider store={this.props.store}>
        <BrowserRouter>
          <ScrollToTop />
          <Switch>
            {spmDomain ? <Route exact path="/"><Redirect to="/presentations" /></Route> : <Route exact path="/" component={HomePage} />}
            <Route exact path="/pricing" component={PricingPage} />
            <Route exact path="/about" component={AboutPage} />
            <Route exact path="/solutions" component={SolutionsPage} />
            <Route exact path="/(v|view)/:alias" component={EmbedRecordPage} />
            <Route exact path="/records_browser" component={RecordsBrowser} />
            <Route exact path="/slideshow" component={Slideshow} />
            <Route exact path="/recorder-test" component={RecorderTest} />
            <Route
              path="/:name?/:service(admin|external|presentations|preview|recorder|remote|streamer|live|self-recorder|screen-recorder|editor|webrtc-test|networking-room|recording-streamer|download)/:admin(admin)?"
            >
              <Suspense fallback={<div style={loaderStyle}><BeatLoader color="#009928" style={loaderStyle} /></div>}>
                <Spm />
              </Suspense>
            </Route>
            {spmDomain &&
              <Route
                path="/:service(presentations|preview|recorder|remote|streamer|live|self-recorder|screen-recorder|editor|webrtc-test|networking-room|recording-streamer|download)/:admin(admin)?"
                component={Spm}
              />
            }
          </Switch>
        </BrowserRouter>
      </Provider >
    );
  }
}
